exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-armed-forces-js": () => import("./../../../src/pages/armed-forces.js" /* webpackChunkName: "component---src-pages-armed-forces-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-courses-coming-soon-js": () => import("./../../../src/pages/courses/coming-soon.js" /* webpackChunkName: "component---src-pages-courses-coming-soon-js" */),
  "component---src-pages-courses-driver-cpc-js": () => import("./../../../src/pages/courses/driver-cpc.js" /* webpackChunkName: "component---src-pages-courses-driver-cpc-js" */),
  "component---src-pages-courses-fors-compliance-js": () => import("./../../../src/pages/courses/fors-compliance.js" /* webpackChunkName: "component---src-pages-courses-fors-compliance-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-courses-shunter-training-js": () => import("./../../../src/pages/courses/shunter-training.js" /* webpackChunkName: "component---src-pages-courses-shunter-training-js" */),
  "component---src-pages-courses-transport-manager-js": () => import("./../../../src/pages/courses/transport-manager.js" /* webpackChunkName: "component---src-pages-courses-transport-manager-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-recruitment-js": () => import("./../../../src/pages/recruitment.js" /* webpackChunkName: "component---src-pages-recruitment-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

